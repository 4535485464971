@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap);
@font-face {
  font-family: "Gotham-Bold";
  src: url(/static/media/Gotham-Bold.f9a5c924.otf) format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Light";
  src: url(/static/media/Gotham-Light.59aa4004.otf) format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Gotham-XLight";
  src: url(/static/media/Gotham-XLight.7737169c.otf) format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "GothamBook";
  src: url(/static/media/GothamBook.58fb9151.ttf);
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Thin";
  src: url(/static/media/Gotham-Thin.6447770f.otf) format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Ultra";
  src: url(/static/media/Gotham-Ultra.5a9f8a04.otf) format("opentype");
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: #2c2a2b;
}

:root {
  --primary: #2c2a2b;
  --secondary: #ed4e21;
  --sideMunuWidth: 58px;
}

input,
textarea,
button {
  outline: none;
  resize: none;
  font-size: inherit;
  border: none;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

ul,
li {
  list-style: none;
}

